import { device } from 'src/styles/breakpoints'
import { orange } from 'src/styles/colors'
import styled from 'styled-components'

import BgCashbackComCartao from './assets/images/bg-cashback-com-cartao.png'
import BgVideoLG from './assets/images/bg-video-lg.png'
import BgVideoSM from './assets/images/bg-video-sm.png'
import BgVideoTablet from './assets/images/bg-video-tablet.png'
import BgVideoXL from './assets/images/bg-video-xl.png'
import BgWhereToEarn from './assets/images/bg-where-to-earn.png'

export const Wrapper = styled.div`

  h1, h2, h4, h5, .returned-cashback {
    font-family: "Sora", Helvetica, sans-serif;
  }

  h2 {
    margin-bottom: 20px;
    line-height: 47.6px;

    @media ${device.desktopLG} {
      line-height: 76.1px;
    }
  }

  #compre-e-ganhe h2 {
    line-height: 25px;

    @media ${device.tablet} {
      line-height: 47.6px;
    }
  }

  h2, h3 {
    letter-spacing: -1px;

    @media ${device.tablet} {
      letter-spacing: -2.5px;
    }
  }

  .invistaGanheImgDiv {
    z-index: -1;
  }

  .cashbackSimulatorDiv {
    @media ${device.tablet} {
      position: inherit;
    }
  }

  .carousel-div{
    @media ${device.desktopLG} {
      .react-multi-carousel-list {
        justify-content: center !important;
      }
    }
  }
`

export const ContentHeader = styled.section`
  align-items: center;
  display: flex;
  min-height: calc(100vh - 64px);
  background-color: ${orange.extra};

  @media ${device.desktopLG} {
    min-height: calc(100vh - 73px);
  }

  h2 {
    font-size: 24px;
    line-height: 105%;

    @media ${device.desktopLG} {
      font-size: 32px;
    }
  }

  .returned-cashback {
    font-size: 32px;
    line-height: 50px;
    font-weight: 300;

    .dollar-sign {
      opacity: 0.5;
      font-weight: none;
    }

    @media ${device.tablet} {
      font-size: 64px;
      line-height: 81px;
    }

    @media ${device.desktopLG } {
      font-size: 96px;
      line-height: 121px;
    }
  }
`

export const ScrollLink = styled.div`
  position: absolute;
  bottom: 20px;
  left: 50%;
  text-align: center;
  line-height: 1.67;
  transform: translateX(-50%);
  width: 100%;

  .circle-chevron {
    width: 40px;
    height: 40px;
    margin: 0 auto;
    border: 1px solid #FFB46E;
    border-radius: 20px;
  }
`

export const ButtonArrow = styled.button`
  width: auto;
  text-transform: none;
`

export const WhatIsCashback = styled.div`
  border: 1px solid #F5F6FA;
  box-shadow: 0px 0px 13.8667px rgba(63, 106, 171, 0.05);

  @media ${device.desktopLG} {
    max-width: 360px;
    margin: 0 auto;
  }
  @media ${device.desktopXL} {
    margin: none;
  }
`

export const CashbackWithCardSection = styled.section`
  @media ${device.tablet} {
    background: url(${BgCashbackComCartao}) no-repeat;
    background-size: 372px;
    background-position: -27px 50%;
  }
  @media ${device.desktopLG} {
    background-size: 442px;
    background-position: 0 50%;
  }
  @media ${device.desktopXL} {
    background-size: 570px;
    background-position: 0 50%;
  }
`

export const CashbackComCartao = styled.section`
  background: url(${BgCashbackComCartao}) no-repeat;
  background-size: 300px;
  background-position: 0 50%;
  @media ${device.tablet} {
    background: none;
  }
`

export const CashbackComCartaoDiv = styled.div`
  background: none;
`

export const ImgSize = styled.div`
  .gatsby-image-wrapper {
    margin: 0 auto;
    width: 248px;
    @media ${device.tablet} {
      width: 284px;
    }
    @media ${device.desktopLG} {
      width: 337px;
    }
    @media ${device.desktopXL} {
      width: 392px;
    }
  }
`

export const ImgSizeCashbackInterShop = styled.div`
  .gatsby-image-wrapper {
    @media ${device.tablet} {
      width: 335px;
    }
    @media ${device.desktopLG} {
      width: 455px;
    }
    @media ${device.desktopXL} {
      width: 552px;
    }
  }
`

export const ImgSizeInvistaGanhe = styled(ImgSizeCashbackInterShop)`
  .gatsby-image-wrapper {
    @media ${device.desktopXL} {
      width: 500px;
    }
  }
`

export const ImgSizeWhereToEarnCashback = styled(ImgSizeCashbackInterShop)`
  .gatsby-image-wrapper {
    @media ${device.desktopXL} {
      width: 500px;
    }
  }
`

export const LogosInterShop = styled.div`
  .gatsby-image-wrapper {
    @media ${device.tablet} {
      float: left;
    }
  }
`
export const BlogCarousel = styled.div`
  .gatsby-image-wrapper {
    max-width: 100% !important;
  }

  .primary-tag, .secundary-tag {
    display: inline;
  }

  &.p-card {
    padding: 0;

    @media ${device.tablet} {
      padding-right: 24px;
    }
    @media ${device.desktopLG} {
      padding-right: 10.5px;
      padding-left: 10.5px
    }
  }

  article {
    display: grid;
    min-height: 350px;
    @media ${device.desktopLG} {
      min-height: 470px;
    }
  }

  .react-multi-carousel-track {
    padding: 30px 0 10px!important;
  }

  .title-post {
    font-family: "Sora", Helvetica, sans-serif;
  }
`
export const PerguntasFrequentes = styled.section`

  .sora {
    font-family: "Sora", Helvetica, sans-serif;
  }

  h2, h3 {
    letter-spacing: .3px !important;

    @media ${device.tablet} {
      letter-spacing: .3px !important;
    }
  }

  input[type=text] {
    background-color: #F5F6FA!important;
  }

  .summary-details {

    .summary {
      background: transparent;
      border-radius: 0;
      border: none;
      border-bottom: solid 0.5px rgba(140, 145, 169, 0.2)!important;
      display: block;
      transition: 100ms all ease;
      width: 100%;

      .summary-content {
        align-items: center;
        padding-left: 0;
        p {
          color: #161616;
        }
        span {
          color: #161616;
          line-height: 19px;
          @media ${device.tablet} {

          }
        }
      }
    }
  }

  button {
    @media ${device.tablet} {
      max-width: 100%;
    }
  }
`

export const ArrowRightCarousel = styled.div`
  border: 1px solid #DEDFE4;
  width: 48px;
  height: 48px;
  padding: 5px;
  border-radius: 50px;
`

export const ArrowLeftCarousel = styled(ArrowRightCarousel)`
  margin-left: 30px;
`

export const WhereToEarnCashbackSection = styled.section`
  background: url(${BgWhereToEarn}) no-repeat;
  background-size: 520px;
  background-position: -20% 50%;

  @media ${device.tablet} {
    background-position: 390px 50%
  }
  @media ${device.desktopLG} {
    background-position: 582px 50%;
  }
  @media ${device.desktopXL} {
    background-size: initial;
    background-position: 100% 50%
  }

  .react-multi-carousel-dot-list {
    display: none;
  }
  .react-multiple-carousel__arrow {
    border: 1px solid #DEDFE4 !important;
    width: 48px;
    height: 48px;
    margin-bottom: 20px;
    padding: 0;
  }
  .react-multiple-carousel__arrow--left {
    left: 0;
  }
  .react-multiple-carousel__arrow--right {
    left: 70px;
  }
  .icon-wrapper {
    width: 330px;
    overflow: hidden;

    @media ${device.desktopLG} {
      width: auto;
    }
  }
  .icons {
    margin-right: 5px;

    @media ${device.tablet} {
      margin-right: 16px;
    }
  }

  .current-icon {
    border-radius: 45px;
    background-color: ${orange.extra};
    padding: 10px;
  }
  .another-icon {
    padding: 10px;
  }
  .margin-icon {
    right: 65px;
    position: relative;
    @media ${device.tablet} {
      right: 128px;
    }
  }
  .margin-icon-none {
    right: 0 !important;
  }
  .opacity {
    position: absolute;
    float: right;
    z-index: 999;
    right: 0;
  }
`

export const VideoDiv = styled.div`
  background: url(${BgVideoSM}) no-repeat;
  background-size: contain;
  display: flex;
  justify-content: center;
  min-height: 522px;
  background-position: center;
  padding: 0 0 40px 0;
  @media ${device.tablet} {
    background: url(${BgVideoTablet}) no-repeat;
    width: 339px!important;
    padding: 0 0 30px 0;
  }
  @media ${device.desktopLG} {
    background: url(${BgVideoLG}) no-repeat;
    width: 394px!important;
    min-height: 526px;
    padding: 0 0 23px 0;
  }
  @media ${device.desktopXL} {
    background: url(${BgVideoXL}) no-repeat;
    width: 394px!important;
    min-height: 666px;
    padding: 0 0 23px 0;
  }
`
export const Video = styled.video`
  width: 201px;
  @media ${device.tablet} {
    width: 199px;
  }
  @media ${device.desktopLG} {
    width: 216px;
  }
  @media ${device.desktopXL} {
    width: 277px;
  }
`
